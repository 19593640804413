import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'assetLocators',
  components: {},
  props: ['binRowData', 'faLocId'],
  watch: {
    currentPage: function() {
      this.getLocDetailsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLocDetailsById();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      locCode: null,
      locName: null,
      freeze: {
        value: null,
        text: null
      },
      status: {
        value: null,
        text: null
      },
      location: {
        value: null,
        text: null
      },
      binData: [
        {
          locator_name: null,
          locator_code: null,
          status: 'ACTIVE',
          parent_locator_id: null,
          fa_location_id: null,
          locator_id: 0,
          freeze_status: 'UNFREEZE'
        }
      ],
      binFields: [
        {
          key: 'add',
          stickyColumn: true,
          class:this.binRowData.freeze_status=="FREEZE"?'d-none':''
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'locator_name',
          label: 'Bin Name'
        },
        {
          key: 'locator_code',
          label: 'Bin Code'
        },
        {
          key: 'status'
        },
        {
          key: 'freeze_status'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      payload: null,
      showValueSetModal: false,
      accessButton: false,
      cntBin:0,
    };
  },
  mounted() {
    if (this.$store.state.shared.accessRights) {
      const buttonRights = this.$store.state.shared.accessRights;
      if (buttonRights.edit_allow && buttonRights.add_allow) {
        this.accessButton = true;
      } else {
        this.accessButton = false;
      }
    }
    if (this.binRowData) {
      this.fillHdrRecordFromShelf(this.binRowData);
    } else {
      this.editMode = true;
    }
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'update') {
    //       // this.editMode = true;
    //     }
    //   }
    // });
  },
  methods: {
    editBinModal() {
      this.editMode = true;
    },
    fillHdrRecordFromShelf(item) {
      this.location.text = item.concatenated_segment;
      this.location.value = item.concatenated_segment;
      this.locName = item.locator_name;
      this.locCode = item.locator_code;
      this.status.text = item.status;
      this.status.value = item.status;
      this.getLocDetailsById();
    },
    getLocDetailsById() {
      const payload = {
        pathVariable:{ 
          faLocId: this.faLocId,      
          },
        params:{
          parent_locator_id:this.binRowData.locator_id

        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLocDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            if (result.length) {
              this.binData = result;
              this.binData.map(ele=>{
                if(ele.freeze_status=="FREEZE"
                  ){
                    this.cntBin++
                  }
              })
              if(this.cntBin==result.length){
                this.binFields[0].class="d-none"
                this.cntBin=0
              }else{
                this.binFields[0].class=""
                this.cntBin=0
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditBinDetails() {
      const payload = this.binData.map(item => {
        return {
          fa_location_id: item.fa_location_id
            ? item.fa_location_id
            : this.binRowData.fa_location_id,
          locator_code: item.locator_code,
          locator_id: item.locator_id,
          locator_name: item.locator_name,
          parent_locator_id: item.parent_locator_id
            ? item.parent_locator_id
            : this.binRowData.locator_id,
          status: item.status,
          freeze_status: item.freeze_status
        };
      });
      var count=0;
      payload.map(item=>{
        if(item.locator_code==null || item.locator_name==null){
         count++
        }
      })
      if(count>0){
        this.$bvToast.toast("Bin name and Bin code can not be null", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }else{
      this.loader = true;
      this.$store
        .dispatch('assets/addLocator', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.binRowData.locator_id =
              response.data.data[0].parent_locator_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
            this.getLocDetailsById();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.showAlert = true;
          this.loader = false;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.error = err;
        });
      }
    },
    addNewRow() {
      if (this.editMode) {
        this.binData.push({
          freeze_status: 'UNFREEZE',
          status: 'ACTIVE',
          locator_name: null,
          locator_code: null,
          parent_locator_id: this.binRowData.locator_id,
          fa_location_id: 0,
          locator_id: 0
        });
      }
    },
    removeRow(index) {
      this.binData.splice(index, 1);
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.locIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_VSET) {
        this.binData[this.locIndex].status = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FREEZE_STATUS_VSET) {
        this.binData[this.locIndex].freeze_status = item.value_code;
      }
      this.binData = JSON.parse(JSON.stringify(this.binData));
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      if (vsetCode === 'STATUS_VSET') {
        this.binData[index].status = null;
        this.binData[index].status = null;
      } else if (vsetCode === 'FREEZE_STATUS_VSET') {
        this.binData[index].freeze_status = null;
        this.binData[index].freeze_status = null;
      }
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
